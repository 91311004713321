import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-import-branding-dialog',
  templateUrl: './import-branding-dialog.component.html',
  styleUrls: ['./import-branding-dialog.component.sass']
})
export class ImportBrandingDialogComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
