import { Component, OnInit, Input } from '@angular/core';
import { SmsService } from 'src/app/services/branding/sms.service';
import { PageBrandingModel  } from 'src/app/Classes/Branding/page-branding-model';
import { SimpleSnackBar, MatSnackBar, MatDialogConfig, MatDialog } from '@angular/material';
import { BrandingService } from 'src/app/services/branding/branding.service';
import { ImportBrandingDialogComponent } from '../import-branding-dialog/import-branding-dialog.component';

@Component({
  selector: 'branding-page',
  templateUrl: './branding-page.component.html',
  styleUrls: ['./branding-page.component.sass']
})
export class BrandingPageComponent implements OnInit {
  
  allPageBranding: PageBrandingModel[];
  currentPageBranding : PageBrandingModel ;
  importPageBranding: PageBrandingModel;  
  _webcodePrefix: string;
  _importWebcode : string;

@Input('webCodePrefix') 
set webCodePrefix (value: string){
  this._webcodePrefix = value;
  this.currentPageBranding = new PageBrandingModel();
  this.currentPageBranding.WebCodePrefix = value;
  this.retrievePageBranding("main", value);
}
get webCodePrefix(){
  return this._webcodePrefix;
}


//PROPERTY importWebcode
set importWebcode (value: string){
  console.log("Set import webcode to ")
  this._importWebcode = value;
  this.importPageBranding = new PageBrandingModel();
  this.importPageBranding.WebCodePrefix = value;
  this.retrievePageBranding("import", value);
}
get importWebcode(){
  return this._importWebcode;
}











  constructor(

    private pageBrandingService : BrandingService,
    private snackBar: MatSnackBar,
    private dialog : MatDialog


  ) { }

  ngOnInit() {
    this.currentPageBranding = new PageBrandingModel();
    this.importPageBranding = new PageBrandingModel();
  }


  retrievePageBranding(forWhom: string="main", webcode : string)
  {
    if (webcode)
    {
        this.pageBrandingService.getPageBrandingForWebcodePrefix(webcode).subscribe(
            data => {
              if (data) {
                if (forWhom=="main")  
                {
                  this.currentPageBranding = data as PageBrandingModel;
                  console.log("Got data for main branding model");
                }
                else
                {
                  this.importPageBranding = data as PageBrandingModel;
                  console.log("Got data for import branding model");
                }
              }
              else {console.log(`No page branding for webcode: ${webcode}`)};
            },
            error => {console.log("Error " + error.message);}
        );
    }
    else
    {
        console.log("Branding-Page component - no webcode was passed from top");
    }

  }



  save()
  {
      console.log(`Saving: webcodeprefix: ${this.currentPageBranding.WebCodePrefix}`);
      this.pageBrandingService.save(this.currentPageBranding).subscribe(
        data => {
          this.snackBar.open(data,"OK",{duration:3000});          
        },
        error => {
          this.snackBar.open("Error " + error.message,"OK",{duration:3000});          
        }
      );
  }

  delete()
  {
    console.log(`Deleting: webcodeprefix: ${this.currentPageBranding.WebCodePrefix}`);
    
    this.pageBrandingService.delete(this.currentPageBranding.WebCodePrefix).subscribe(
      data => {
        this.snackBar.open(data as string,"OK",{duration:3000});
        this.currentPageBranding = new PageBrandingModel();
        this.currentPageBranding.WebCodePrefix = this.webCodePrefix;          
      },
      error => {
        this.snackBar.open("Error " + error.message,"OK",{duration:3000});          
      }
    );

  }

  import(what : string)
  {
      switch (what.toLocaleLowerCase())
      {
          case "pagetitle":
          {
              this.currentPageBranding.PageTitle = this.importPageBranding.PageTitle;
              break;
          }
          case "facebooktitle":
          {
              this.currentPageBranding.FacebookTitle = this.importPageBranding.FacebookTitle;
              break;
          }
          case "facebookdescription":
          {
              this.currentPageBranding.FacebookDescription = this.importPageBranding.FacebookDescription;
              break;
          }
          case "twittertitle":
          {
              this.currentPageBranding.TwitterTitle = this.importPageBranding.TwitterTitle;
              break;
          }
          case "twitterdescription":
          {
              this.currentPageBranding.TwitterDescription = this.importPageBranding.TwitterDescription;
              break;
          }
          case "backgroundurl":
          {
              this.currentPageBranding.BackgroundUrl = this.importPageBranding.BackgroundUrl;
              break;
          }
          case "toplogourl":
          {
              this.currentPageBranding.TopLogoUrl = this.importPageBranding.TopLogoUrl;
              break;
          }
          case "toplogohref":
          {
              this.currentPageBranding.TopLogoHRef = this.importPageBranding.TopLogoHRef;
              break;
          }
          case "bottomlogourl":
          {
              this.currentPageBranding.BottomLogoUrl = this.importPageBranding.BottomLogoUrl;
              break;
          }
          case "bottomlogohref":
          {
              this.currentPageBranding.BottomLogoHRef = this.importPageBranding.BottomLogoHRef;
              break;
          }
          case "cssstyle":
          {
              this.currentPageBranding.CSSStyle = this.importPageBranding.CSSStyle;
              break;
          }
          


      }



  }
 


}
