import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { User } from '../Classes/user';
import { AuthService } from './auth.service';
import { GService } from './g.service';
import { Observable } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class UserService {

  constructor(
    private http: HttpClient,
    private auth : AuthService,
    private g: GService
    ) { 


  }

  getAllUsers() : Observable<any>
  {    
    var url = this.g.authBase + "/api/users";
    return this.http.get(url, {headers : new HttpHeaders({'Authorization': 'Bearer ' + localStorage.getItem("token")})});
  }

}
