import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginComponent } from './components/account/login/login.component';
import { BrandingHomeComponent } from './components/eventBranding/branding-home/branding-home.component';
import { FormsModule }   from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClient, HttpClientModule } from '@angular/common/http';

//UI Frameworks
import { MaterialModule } from './modules/material/material.module';
import { NgPrimeUIElementsModule } from './modules/ng-prime-uielements/ng-prime-uielements.module';

//this App
import { NavbarComponent } from './components/mainblocks/navbar/navbar.component';
import { GService } from './services/g.service';
import { AuthService } from './services/auth.service';
import { UserListComponent } from './components/account/user-list/user-list.component';
import { BrandingSmsComponent } from './components/eventBranding/branding-sms/branding-sms.component';
import { BrandingPageComponent } from './components/eventBranding/branding-page/branding-page.component';
import { ImportBrandingDialogComponent } from './components/eventBranding/import-branding-dialog/import-branding-dialog.component';
import { MatDialogModule } from '@angular/material';

 MatDialogModule

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    BrandingHomeComponent,
    NavbarComponent,
    UserListComponent,
    BrandingSmsComponent,
    BrandingPageComponent,
    ImportBrandingDialogComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    BrowserAnimationsModule,
    MaterialModule,
    NgPrimeUIElementsModule,
    HttpClientModule,
    MatDialogModule
  ],
  providers: [GService, AuthService, HttpClient],
  bootstrap: [AppComponent]
})
export class AppModule { }
