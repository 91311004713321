import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { GService } from './g.service';
import { map } from "rxjs/operators";
import { Observable } from 'rxjs';
import { LoginResponse } from '../Classes/login-response';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(private http: HttpClient, private g: GService) { }


  getToken(username: string, password: string): Observable<any>
  {
    return this.http.post(this.g.getTokenEndPoint(),{"username": username, "password": password});
  }

  storeLogin(loginData:LoginResponse)
  {
    localStorage.setItem("token",loginData.token);
    localStorage.setItem("tokenExpiration",loginData.expiration.toString());
  }
}
