import { Component, OnInit } from '@angular/core';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material';
import { GService } from 'src/app/services/g.service';
import { AuthService } from 'src/app/services/auth.service';
import { LoginResponse } from 'src/app/Classes/login-response';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.sass']
})
export class LoginComponent implements OnInit {

  username: string;
  password: string;


  constructor( 
    private snackBar: MatSnackBar,  
    public g: GService,
    private auth: AuthService
    
    ) { }

  ngOnInit() {
  }

  setLoginData(data:LoginResponse)
  {
    console.log("Successfull login " + JSON.stringify(data));
    this.snackBar.open(`Login Successfull. Welcome to WPE4!`,"OK",{duration: 3000});
    this.auth.storeLogin(data);
  }

  loginButtonClicked()
  {


    //this.snackBar.open(`Login Clicked : Username: ${this.username} password ${this.password}`,"OK",{duration:3000});
    var response = this.auth.getToken(this.username,this.password).subscribe(
        data => this.setLoginData(data),        
        error => {
          if (error.status == 401)
            this.snackBar.open("Access denied. Try again.","OK",{duration:3000});
          else
            this.snackBar.open("Problem logging in: " + error.message, "OK", {duration:3000});          
        },
    );


    

  }

}
