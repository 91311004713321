import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import {ButtonModule} from 'primeng/button';
import {InputTextModule} from 'primeng/inputtext';
import {FieldsetModule} from 'primeng/fieldset';

import {MenubarModule} from 'primeng/menubar';
 

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    ButtonModule, 
    InputTextModule,
    FieldsetModule,
    MenubarModule, 
  ],
  exports:
  [
    ButtonModule, 
    InputTextModule,
    FieldsetModule,
    MenubarModule
  ]
})
export class NgPrimeUIElementsModule { }
