import { Injectable } from '@angular/core';
import { Location, JsonPipe } from '@angular/common';


@Injectable({
  providedIn: 'root'
})


export class GService {

  webcode:string;
  apiBase: string;
  authBase: string;
  window: any;
  tokenEndpoint: string;



  constructor(private location: Location) { 

  
    //this.tokenEndpoint = "https://localhost:44396/api/auth/token";
     
      let forceRemoteApi : boolean = true;

      if (window.location.hostname=="localhost" && !forceRemoteApi )
      {
          this.apiBase = "http://localhost:8888";
          this.authBase = "https://localhost:44399";
      }
      else{
          this.apiBase = "https://v4.wellplanned.events/backend";
          this.authBase = "https://logins.wellplanned.events";
      }
      console.log(`Running on ${window.location.hostname}, setting apiBase to ${this.apiBase}` );
  }


  getTokenEndPoint() : string
  {
      return this.authBase + "/login";
  }

  formatResourceEndpoint(relativepath: string):string{        
    if(relativepath[0]!="/") relativepath = "/" + relativepath;
    return this.apiBase + relativepath;
  }


}
