import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { SMSTemplate } from 'src/app/Classes/Branding/smstemplate';
import { AuthService } from '../auth.service';
import { GService } from '../g.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SmsService {

   headerOptions: any;

  constructor(private http: HttpClient, private g: GService) {
   }


   getListOfSmsTextTemplates(): Observable<any>
   {      
     let url : string = this.g.formatResourceEndpoint("/api/branding/smstemplates");
     console.log("Getting from: "+ url);
      return this.http.get(url);
   }

   getSmsTemplateForWebcodePrefix(webcodeprefix: string)
   {
      let url : string = this.g.formatResourceEndpoint("api/branding/smstemplates/"+webcodeprefix);
      return this.http.get(url);
   }
   save(template : SMSTemplate):Observable<any>
   {
      let url : string = this.g.formatResourceEndpoint("api/branding/smstemplates");
      return this.http.post(url,template);
   }
   delete (webcodeprefix: string)
   {
      let url : string = this.g.formatResourceEndpoint("api/branding/smstemplates/" + webcodeprefix);
      return this.http.delete(url);
   }
}
