import { Component, OnInit } from '@angular/core';
import { User } from '../../../Classes/user'
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-user-list',
  templateUrl: './user-list.component.html',
  styleUrls: ['./user-list.component.sass']
})
export class UserListComponent implements OnInit {

  users: User[];
  constructor(private userService: UserService) { 
    

  }

  ngOnInit() {
    this.retrieveUsers();
  }
  retrieveUsers ()
  {
      this.userService.getAllUsers().subscribe(
        data => {console.log("Getting users " + JSON.stringify(data))},
        error => {console.log("Err getting users " + error.message)}
      )
  }

}
