import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import {  PageBrandingModel } from 'src/app/Classes/Branding/page-branding-model';
import { AuthService } from '../auth.service';
import { GService } from '../g.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class BrandingService {

  endpoint : string = "/api/branding/pages";

  constructor(private http: HttpClient, private g: GService) {
  }


  getListOfAllPageBranding(): Observable<any>
  {      
    let url : string = this.g.formatResourceEndpoint(this.endpoint);
    console.log("Getting from: "+ url);
     return this.http.get(url);
  }

  getPageBrandingForWebcodePrefix(webcodeprefix: string)
  {
     let url : string = this.g.formatResourceEndpoint( this.endpoint + "/" +webcodeprefix);
     return this.http.get(url);
  }

  save(model : PageBrandingModel):Observable<any>
   {
      let url : string = this.g.formatResourceEndpoint( this.endpoint );
      return this.http.post(url,model);
   }
   delete (webcodeprefix: string)
   {
      let url : string = this.g.formatResourceEndpoint( this.endpoint + "/" + webcodeprefix);
      return this.http.delete(url);
   }


}
