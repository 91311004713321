import { Component, OnInit, Input } from '@angular/core';
import { SmsService } from 'src/app/services/branding/sms.service';
import { SMSTemplate } from 'src/app/Classes/Branding/smstemplate';
import { SimpleSnackBar, MatSnackBar } from '@angular/material';

@Component({
  selector: 'branding-sms',
  templateUrl: './branding-sms.component.html',
  styleUrls: ['./branding-sms.component.sass']
})
export class BrandingSmsComponent implements OnInit {
  _webcodePrefix: string;

  @Input('webCodePrefix') 
set webCodePrefix (value: string){
  this._webcodePrefix = value;
  this.currentSmsTemplate = new SMSTemplate();
  this.currentSmsTemplate.WebCodePrefix = value;
  this.retrieveSMSTemplate();
}
get webCodePrefix(){
  return this._webcodePrefix;
}

  allSmsTemplates: SMSTemplate[];
  currentSmsTemplate : SMSTemplate ;

  constructor(
    private smsService : SmsService,
    private snackBar: MatSnackBar) { }

  ngOnInit() {
    //get all sms templates on component creation. This is just a test - remove this later. 
    //this.smsService.getListOfSmsTextTemplates().subscribe(data=>{this.allSmsTemplates=data;});    

    this.currentSmsTemplate = new SMSTemplate();
  }

  retrieveSMSTemplate()
  {
    if (this.webCodePrefix)
    {
        this.smsService.getSmsTemplateForWebcodePrefix(this.webCodePrefix).subscribe(
            data => {
              if (data) {this.currentSmsTemplate = data as SMSTemplate;}
              else {console.log(`No SMS template for webcode: ${this.webCodePrefix}`)};
            },
            error => {console.log("Error " + error.message);}
        );
    }
    else
    {
        console.log("Branding-SMS component - no webcode was passed from top");
    }

  }


  save()
  {
      console.log(`Saving: webcodeprefix: ${this.currentSmsTemplate.WebCodePrefix}, text: ${this.currentSmsTemplate.SMSText}`);
      this.smsService.save(this.currentSmsTemplate).subscribe(
        data => {
          this.snackBar.open(data,"OK",{duration:3000});          
        },
        error => {
          this.snackBar.open("Error " + error.message,"OK",{duration:3000});          
        }
      );
  }

  delete()
  {
    console.log(`Deleting: webcodeprefix: ${this.currentSmsTemplate.WebCodePrefix}`);
    
    this.smsService.delete(this.currentSmsTemplate.WebCodePrefix).subscribe(
      data => {
        this.snackBar.open(data as string,"OK",{duration:3000});
        this.currentSmsTemplate = new SMSTemplate();
        this.currentSmsTemplate.WebCodePrefix = this.webCodePrefix;          
      },
      error => {
        this.snackBar.open("Error " + error.message,"OK",{duration:3000});          
      }
    );

  }

}
