export class PageBrandingModel {
    ID: number;
    WebCodePrefix: string;
    PageTitle: string;
    FacebookDescription: string;
    FacebookTitle: string;
    TwitterTitle: string;
    TwitterDescription: string;
    BackgroundUrl: string;
    TopLogoUrl: string;
    TopLogoHRef: string;
    BottomLogoUrl: string;
    BottomLogoHRef: string;
    DomainName: string;
    CSSStyle: string;
}
