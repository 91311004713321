import { Component } from '@angular/core';
import { MenuItem } from 'primeng/components/common/menuitem';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent  {
  
 

  title = 'WellPlanned.Events V4';

  ngOnInit()
  {
     


  }
 
}
