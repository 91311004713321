import { Component, OnInit } from '@angular/core';
import { MatSnackBar, SimpleSnackBar } from '@angular/material';
import { GService } from 'src/app/services/g.service';
import { SmsService } from 'src/app/services/branding/sms.service';
import { SMSTemplate } from 'src/app/Classes/Branding/smstemplate';

@Component({
  selector: 'app-branding-home',
  templateUrl: './branding-home.component.html',
  styleUrls: ['./branding-home.component.sass']
})
export class BrandingHomeComponent implements OnInit {

  webcode: string;
  allSmsTemplates: SMSTemplate[];
  allSmsTemplatesVisible: boolean = false;

  constructor(
    private snack: MatSnackBar, 
    private g : GService,
    private smsService: SmsService
    ) { }

  ngOnInit() {
    this.webcode = localStorage.getItem("webcode") || "";
  }

  saveWebcode()
  {
    this.g.webcode = this.webcode;
    localStorage.setItem("webcode",this.webcode);    
    this.snack.open("Webcode saved","OK",{duration:3000});
  }

  getAllSmsTemplates()
  {
  this.smsService.getListOfSmsTextTemplates().subscribe(
    data=>{
      this.allSmsTemplates = data as SMSTemplate[];
      this.allSmsTemplatesVisible = true;
    },
    error=>{
      console.log("ERROR " + JSON.stringify(error));
    })
  }

}
