import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './components/account/login/login.component';
import { BrandingHomeComponent } from './components/eventBranding/branding-home/branding-home.component';
import { UserListComponent } from './components/account/user-list/user-list.component';

const routes: Routes = [
  {path:"login",component:LoginComponent},
  {path:"event-branding-home",component:BrandingHomeComponent},
  {path:"users-home",component:UserListComponent}


];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
